<template>
  <div id="page-support">
    <h5 class="m-4">{{$t('AUTH.MENU.APIKEYS')}}</h5>

    <APIKeysTable
      :items="apiKeys"
      @createApiKeyClicked="createApiKeyClicked"
      @selectApiKeyClicked="selectApiKeyClicked"
      @deleteApiKeyClicked="deleteApiKeyClicked"
      @toastr="toastr"
    />
    <div class="scroll-to-container"></div>

    <!-- Creating Todo  -->
    <b-card
      title=""
      class="mb-2 edit-apikey-container"
      ref="edit-apikey"
      id="edit-apikey"
      hide-footer
      v-if="creating || editing"
    >
      <b-form class='d-flex flex-column' @submit="saveApiKey($event)">

        <b-card
          :title="$t('PAGES.APIKEYS.API_KEY_DATA')"
        >
          <b-form-group label="Huvudförening" label-for="input-company_id">
            <b-form-select id="input-company_id" placeholder="Välj förening" v-model="form.company_id" :options="companyOptions">
            </b-form-select>
          </b-form-group>

          <b-form-group 
            :label="$t('PAGES.APIKEYS.API_SERVICE')" label-for="input-service">
            <b-form-select id="input-service" placeholder="" v-model="form.service" :options="serviceOptions">
            </b-form-select>
          </b-form-group>

          <b-card
            v-if="form.service==='SPAR'"
            title="SPAR Inställningar"
            class="mb-8"
            >
            <b-form-group label="SPAR Server" label-for="input-url">
              <b-form-select id="input-url" placeholder="Välj server" v-model="form.url" :options="urlOptions">
              </b-form-select>
            </b-form-group>

            <b-form-group label="Client-ID" label-for="input-client_id">
              <b-form-input type="text" v-model.trim="form.client_id" />
              <b-form-text id="input-live-help">{{$t('PAGES.APIKEYS.EXISTS_IN_SPAR_ADMIN')}}</b-form-text>
            </b-form-group>

            <b-form-group label="Client-Secret" label-for="input-client_secret">
              <b-form-input type="text" v-model.trim="form.client_secret" />
              <b-form-text id="input-live-help">{{$t('PAGES.APIKEYS.EXISTS_IN_SPAR_ADMIN')}}</b-form-text>
            </b-form-group>

            <b-form-group label="Access-Token" label-for="input-client_secret">
              <b-form-input type="password" v-model.trim="form.access_token" />
              <b-form-text id="input-live-help">{{$t('PAGES.APIKEYS.SET_AFTER_SUCCESS_SPAR')}}</b-form-text>
            </b-form-group>


            <b-form-group label="Ställ in Access-Token genom att klicka här" label-for="input-client_secret">
              <b-button type="submit" variant="success" @click="connectSPAR">{{$t('PAGES.APIKEYS.FETCH_ACCESS_TOKEN')}}</b-button>
            </b-form-group>
            <b-form-group>
              <b-button type="submit" variant="primary">{{$t('COMMON.SAVE')}}</b-button>
            </b-form-group>

          </b-card>

          <b-card
            v-if="form.service==='SINCH'"
            title="Sinch Inställningar"
            class="mb-8"
            >
            <b-form-group label="SMS Nummer" label-for="input-from_number">
              <b-form-input type="text" v-model.trim="form.external_data.from_number" />
              <b-form-text id="input-from_number-help">{{$t('PAGES.APIKEYS.MUST_BE_SINCH_PHONE')}}</b-form-text>
            </b-form-group>

            <b-form-group label="Service Plan ID" label-for="input-service_plan_id">
              <b-form-input type="text" v-model.trim="form.external_data.service_plan_id" />
              <b-form-text id="input-service_plan_id-help">{{$t('PAGES.APIKEYS.SET_SERVICEPLAN_ID')}}</b-form-text>
            </b-form-group>

            <b-form-group label="Token" label-for="input-access_token">
              <b-form-input type="text" v-model.trim="form.access_token" />
              <b-form-text id="input-access_token-help">{{$t('PAGES.APIKEYS.CHECK_SINCH_ADMIN')}}</b-form-text>
            </b-form-group>

            <b-form-group>
              <b-button type="submit" variant="primary">{{$t('COMMON.SAVE')}}</b-button>
            </b-form-group>

          </b-card>

          <b-card
            v-if="form.service==='DINTERO'"
            :title="$t('PAGES.APIKEYS.DINTERO_SETTINGS')"
            class="mb-8">

            <b-form-group label="Dintero Payment Profile ID" label-for="input-service_id">
              <b-form-input type="text" v-model.trim="form.service_id" />
            </b-form-group>

            <!--
          <b-form-group label="Status" label-for="input-status">
            <b-form-select id="input-status" placeholder="Välj status" v-model="form.status" :options="statusOptions">
            </b-form-select>
          </b-form-group>

            -->

            <b-button type="submit" variant="primary" >{{$t('COMMON.SAVE')}}</b-button>

          </b-card>

          <b-card
            v-if="form.service==='STRIPE'"
            :title="$t('PAGES.APIKEYS.STRIPE_SETTINGS')"
            class="mb-8">

            <b-form-group label="Access-Token" label-for="input-client_secret">
              <b-form-input type="password" v-model.trim="form.access_token" />
              <b-form-text id="input-live-help"></b-form-text>
            </b-form-group>

            <b-form-group label="PK" label-for="input-webhook_url">
              <b-form-input type="text" v-model.trim="form.external_data.pk" />
            </b-form-group>

            <b-form-group label="Webhook URL" label-for="input-webhook_url">
              <b-form-input type="text" v-model.trim="form.external_data.webhook_url" />
            </b-form-group>

            <b-form-group label="Webhook Signing Secret" label-for="input-webhook_signing_secret">
              <b-form-input type="text" v-model.trim="form.external_data.webhook_signing_secret" />
            </b-form-group>

            <!--
          <b-form-group label="Status" label-for="input-status">
            <b-form-select id="input-status" placeholder="Välj status" v-model="form.status" :options="statusOptions">
            </b-form-select>
          </b-form-group>

            -->

            <b-button type="submit" variant="primary" >{{$t('COMMON.SAVE')}}</b-button>

          </b-card>



        </b-card>

      </b-form>
    </b-card>

  </div>
</template>



<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import APIKeysTable from '@/view/pages/ml/apikeys/APIKeysTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'apikeys',
  components: {
    
    APIKeysTable,
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUserId', 'currentUser', 'companies']),
    disabledSave(){
      return !this.validOrgNr;
    },
    companyOptions() {
      return this.companies.map((item) => ({ text: item.name, value: item.company_id }));
    }
  },
  mounted() {
    this.loadData();
  },
  validations: {

  },
  mixins: [ toasts ],
  data() {
    return {
      statusOptions: [
        { value: 'CLAIMED', text: 'Aktivt' },
        { value: 'UNCLAIMED', text: 'Väntar' },
        { value: 'REVOKED', text: 'Spärrat' },
      ],
      serviceOptions: [
        { value: 'FORTNOX', text: 'Fortnox' },
        { value: 'SPAR', text: 'SPAR' },
        { value: 'DINTERO', text: 'Dintero' },
        { value: 'SINCH', text: 'Sinch' },
        { value: 'STRIPE', text: 'Stripe' },
      ],
      urlOptions: [
        { value: 'https://spar.memlist.se', text: 'spar.memlist.se' }
      ],
      form: {
        id: '',
        company_id: '',
        external_data: {},
        created_at: '',
        updated_at: '',
        status: '',
        public_token: '',
        access_token: '',
        service: '',
      },
      creating: false,
      editing: false,
      apiKeys: [],
      validOrgNr: null
    };
  },
  watch: {
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.loadApiKeys();
      }
    },
    'form.external_data.orgNr': function (newVal, oldVal) {
      this.validOrgNr = !newVal ? null : this.validate_orgNr(newVal);
    }
  },
  methods: {

    async loadData() {
      if (!this.currentCompanyId) {
        return;
      }
      
      this.loadApiKeys();
    },

    connectSPAR() {
      axios
        .post('/api_key/spar_access_token', this.form)
        .then(res => {
          this.form.access_token = res.access_token;

          this.toastr('success', this.$t('COMMON.OK'), 'Access-Token hämtad');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Gick inte att koppla access token');
        });
    },

    createApiKeyClicked() {
      this.resetForm()
      this.creating = true;
      this.editing = false;

      this.scrollToEditApiKey();
    },

    connectFortnox() {
      axios
        .post('/api_key/connectapi', {
          company_id: this.form.company_id,
          service: 'fortnox',
          public_token: this.form.public_token
        })
        .then(res => {
          if (res.status === 200) {
            this.toastr('success', this.$t('COMMON.OK'), 'Fortnox har kopplats');


          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte koppla fortnox');
        });
    },

    createApiKey() {
      axios
        .post('/api_key', this.form )
        .then(res => {
          if (res.status === 201) {
            this.apiKeys.push(res.data);
            this.toastr('success', this.$t('COMMON.OK'), 'Api nyckeln har skapats');
          }
          else if (res.status === 406) {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Det finns redan en API nyckel för den tjänsten och föreningen');
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa Api nyckel');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa Api nyckeln');
        });
    },

    deleteApiKeyClicked(id) {
      axios
        .delete(`/api_key/${id}?company_id=${this.currentCompanyId}`)
        .then(res => {
          this.apiKeys = this.apiKeys.filter(item => item.id !== id);

          this.creating = false;
          this.editing = false;

          this.toastr('success', this.$t('COMMON.OK'), 'Api nyckeln togs bort');
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort Api nyckel');
        });
    },

    selectApiKeyClicked(id) {

      axios
        .get(`/api_key/${id}`)
        .then(res => {
          this.apiKeySelected = res.data;
          this.form = res.data;
          this.editing = true;

          this.scrollToEditApiKey(true);
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta Api nyckel');
        });
    },

    getLangText(lang_text, lang) {
      for (var i = 0; i < lang_text.length; ++i) {
        if (lang_text[i].lang === lang) {
          return lang_text[i].text;
        }
      }

      return 'Språköversättning saknas';
    },

    loadApiKeys() {

      this.apiKeys = [];
      axios
        .get(`/api_key`)
        .then(res => {
          if (res.status === 200) {
            this.apiKeys = res.data;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista Api nycklar');
        });
    },

    scrollToEditApiKey(withId = false) {
      var that = this;
      setTimeout(function () {
        const el = withId ? document.getElementById('support-task-table') : that.$el.getElementsByClassName('scroll-to-container')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },

    saveApiKey(e) {
      if (e !== undefined) {
        e.preventDefault();
      }
      
      if (this.creating) {
        this.createApiKey();
      }
      else {
        axios
        .put(`/api_key/${this.form.id}`, this.form)
        .then(res => {
          if (res.status === 204) {
            this.toastr('success', this.$t('COMMON.OK'), 'Api nyckel uppdaterades');
            this.creating = false;
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte uppdatera Api nyckel');
        });
      }
    },

    resetForm(){
      this.form = {
          id: '',
          company_id: '',
          service: '',
          access_token: '',
          public_token: '',
          external_data: {},
          status: ''
      }
    },

  }
};
</script>
